export const autoSaveFeedback = () => {
  const okComments = document.querySelectorAll("#ok-comments");
  const koComments = document.querySelectorAll("#ko-comments");
  const scores = document.querySelectorAll("#feedback_feedback_score");
  const personalizedOkComments = document.querySelectorAll(
    "#ok-comments-personalized"
  );

  okComments.forEach((okComment) => {
    okComment.addEventListener("input", (e) => {
      const content = e.currentTarget.value;
      const feedbackId = e.currentTarget.dataset["feedbackId"];
      const commentType = "ok_comment";
      const score = 0;
      updateFeedback(content, feedbackId, commentType, score);
    });
  });

  koComments.forEach((koComment) => {
    koComment.addEventListener("input", (e) => {
      const content = e.currentTarget.value;
      const feedbackId = e.currentTarget.dataset["feedbackId"];
      const commentType = "ko_comment";
      const score = 0;
      updateFeedback(content, feedbackId, commentType, score);
    });
  });

  scores.forEach((score, i) => {
    score.addEventListener("change", (e) => {
      const score = e.currentTarget.value;
      const feedbackId = okComments[i].dataset["feedbackId"];
      const commentType = "score";
      const content = "";
      updateFeedback(content, feedbackId, commentType, score);
    });
  });

  personalizedOkComments.forEach((personalizedComment) => {
    personalizedComment.addEventListener("input", (e) => {
      const content = e.currentTarget.value;
      const personalizedFeedbackId = e.currentTarget.dataset["personalizedFeedbackId"];
      updatePersonalizedFeedback(content, personalizedFeedbackId);
    });
  });
};

export const updateFeedback = (content, feedbackId, commentType, score) => {
  fetch(`/feedback_comment_update/`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      content: content,
      feedback_id: feedbackId,
      comment_type: commentType,
      score: score,
    }),
  });
};

export const updatePersonalizedFeedback = (comment, personalizedFeedbackId) => {
  fetch(`/personalized_feedback_update/`, {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      personalized_feedback_id: personalizedFeedbackId,
      comment: comment,
    }),
  });
};
